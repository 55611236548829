/* App.css */

/* Tooltip style */
.tooltip-container {
  position: relative;
  display: inline-block;
}

/*
width: 120px;
*/
.tooltip-text {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
  bottom: calc(100% + 10px);
  left: 50%;
  margin-left: -60px;

  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.tooltip-container:hover {
  cursor: pointer;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* StatusIndicator style */
.status-enabled {
  background-color: green;
  color: white;
  padding: 0.2rem;
  border-radius: 0.25rem;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.status-disabled {
  background-color: red;
  color: white;
  padding: 0.2rem;
  border-radius: 0.25rem;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.status-information {
  background-color: #9e9e9e;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2px;
  padding-top: 2px;
  border-radius: 0.25rem;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.status-warning {
  background-color: #e9db18;
  color: black;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2px;
  padding-top: 2px;
  border-radius: 0.25rem;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.status-sponsor {
  background-color: #b6409c;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2px;
  padding-top: 2px;
  border-radius: 0.25rem;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.status-blue {
  background-color: #5f8c9e;
  color: white;
  padding: 0.2rem;
  border-radius: 0.25rem;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

/* Home page style */

.PluginsIconButton {
  color: #000000;
  padding: 0;
  gap: 0;
}

.HeaderButton {
  color: #4CAF50;
}

.menu-background {
  background-color: #e2e2e2;
}

.main-background {
  background-color: #c4c2c2;
}

/* Header style */
.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin: 0;
  padding: 0;
  height: 40px;
}

/* Navigation style */
nav {
  display: flex;
  align-items: center;
}

/* Navigation in Header.js style */
.nav-link {
  margin: 0 10px;
  font-size: 20px;
  text-decoration: none;
  color: #333;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #007BFF;
}

.nav-link:hodddver {
  color: #ff0000;
}

/* Table style */
table {
  border-collapse: collapse;
  width: 100%;
}

thead {
  position: sticky;
  top: 0;
  border: 1px solid #ddd;
  z-index: auto;
}

thead th {
  border: 1px solid #ddd;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  color: white;
  background: #4CAF50;
  text-align: left;
}

tbody td {
  border: 1px solid #ddd;
  margin: 0;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  text-align: left;
  font-size: 14px;
}

.table-content-even {
  background-color: #bdbdbd;
}

.table-content-odd {
  background-color: #9e9e9e;
}

.table-content-selected {
  background-color: #5f8c9e;
}

h3 {
  margin: 0;
}

/* Mbf table content style 
.MbfWindowDiv table,
.MbfWindowDiv table thead,
.MbfWindowDiv table tbody,
.MbfWindowDiv table tr,
.MbfWindowDiv table th,
.MbfWindowDiv table td {
  border: none;
  box-shadow: none;
}
*/

/* Define the style for the main page element */
.MbfPageDiv {
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
  width: 100%;
  margin: 0px;
  padding: 0px;
  gap: 20px;
}

/* Define the style for the main div element */
.MbfWindowDiv {
  display: flex;
  flex-direction: column;
  box-shadow: 5px 5px 10px #888;
  border: 1px solid #ddd;
  box-sizing: border-box;
  background-color: #9e9e9e;
}

/* Define the style for the main div element */
.MbfWindowDivTable {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin: -1px;
  padding: 0;
  gap: 0;
  overflow: auto;
  display: block;
}

/* Define the style for the header element */
.MbfWindowHeader {
  display: 'flex';
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #ddd;
  background-color: #4CAF50;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/* Define the style for the header element */
.MbfWindowFooter {
  display: 'flex';
  align-items: center;
  justify-content: center;
  height: 40px;
  color: black;
  margin: 0px;
  padding: 10px;
  padding-top: 0px;
}

/* Define the style for the header text element */
.MbfWindowHeaderText {
  color: white;
  font-weight: bold;
  margin: 0px;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
}

/* Define the style for the body element width: 100%;*/
.MbfWindowBodyColumn {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  margin: 0px;
  padding: 10px 0px 10px 0px;
  gap: 0px;
  overflow: auto;
}

/* Define the style for the body element */
.MbfWindowBodyRow {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  height: 100%;
  padding: 0px 10px 0px 10px;
  margin: 0px;
  padding: 0px;
  gap: 0px;
  overflow: auto;
}

/* Table header style */
.MbfScreen {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 40px);
  height: calc(100vh - 40px);
  gap: 20px;
  margin: 0;
  padding: 20px;
  background-color: #c4c2c2;
}

/* Styles for mobile screens */
@media (max-width: 1600px) {
  .MbfScreen {
    width: 1600px;
    height: 800px;
  }
}